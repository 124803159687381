<template>
  <section id="advantages" class="advantages">
    <div class="container">
      <div
        class="advantages__title"
        v-if="content.advantages_title"
        data-aos="custom-appearance"
        data-aos-duration="1000"
        v-html="content.advantages_title"></div>
      <div
        class="advantages__description"
        v-if="content.advantages_desc"
        data-aos="custom-appearance"
        data-aos-duration="1000"
        data-aos-delay="100"
        v-html="content.advantages_desc"></div>
      <ul class="advantages__list" v-if="content.advantages_list">
        <ItemAdvantages
          :content="item"
          :order="index + 1"
          v-for="(item, index) in content.advantages_list"
          :key="item" />
      </ul>
    </div>
  </section>
</template>

<script>
import ItemAdvantages from "@/components/block/ItemAdvantagesApp.vue";
export default {
  components: {
    ItemAdvantages,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.advantages,
    };
  },
};
</script>

<style>
.advantages {
  margin-bottom: 170px;
}

.advantages__title {
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  text-align: center;
  color: var(--title2-color-white);
}

.advantages__description {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--subtitle3-color-white);
}

.advantages__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . . .";
}

@media (max-width: 1599.98px) {
  .advantages {
    margin-bottom: 150px;
  }

  .advantages__title {
    font-size: 40px;
  }

  .advantages__description {
    max-width: 450px;
    margin-bottom: 50px;

    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .advantages {
    margin-bottom: 130px;
  }

  .advantages__title {
    font-size: 32px;
  }

  .advantages__description {
    max-width: 364px;
    margin-bottom: 45px;
  }
}

@media (max-width: 991.98px) {
  .advantages {
    margin-bottom: 110px;
  }

  .advantages__title {
    font-size: 28px;
  }

  .advantages__description {
    margin-bottom: 40px;
    font-size: 14px;
  }

  .advantages__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 40px 20px;
    grid-template-areas: ". .";
  }
}

@media (max-width: 767.98px) {
  .advantages {
    margin-bottom: 90px;
  }

  .advantages__title {
    font-size: 26px;
  }

  .advantages__description {
    max-width: calc(100% - 30px);
    width: 364px;
    margin-bottom: 35px;
  }

  .advantages__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 45px 20px;
    grid-template-areas: ".";
  }
}
</style>
