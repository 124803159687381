<template>
  <section id="additional-offer" class="additional-offer">
    <div class="container">
      <div
        class="additional-offer__title"
        v-if="content.additional_offers_title"
        data-aos="custom-appearance"
        data-aos-duration="800"
        v-html="content.additional_offers_title"></div>
      <div
        class="additional-offer__description"
        v-if="content.additional_offers_desc"
        data-aos="custom-appearance"
        data-aos-duration="800"
        data-aos-delay="200"
        v-html="content.additional_offers_desc"></div>
      <ul class="additional-offer__list" v-if="content.additional_offers_list">
        <ItemAdditionalOffer
          v-for="(item, index) in content.additional_offers_list"
          :key="item"
          :content="item"
          :order="index"
          :popup="content.additional_offers_popup" />
      </ul>
    </div>
  </section>
</template>

<script>
import ItemAdditionalOffer from "@/components/block/ItemAdditionalOfferApp.vue";

export default {
  components: {
    ItemAdditionalOffer,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.additional_offers,
    };
  },
};
</script>

<style>
.additional-offer {
  margin-bottom: 170px;
}

.additional-offer__title {
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--title2-color-white);
}

.additional-offer__description {
  max-width: 500px;
  margin: 0 auto 70px auto;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--subtitle3-color-white);
}

.additional-offer__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . . .";
}

@media (max-width: 1599.98px) {
  .additional-offer {
    margin-bottom: 150px;
  }

  .additional-offer__title {
    font-size: 40px;
  }

  .additional-offer__description {
    max-width: 450px;
    margin-bottom: 60px;

    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .additional-offer {
    margin-bottom: 130px;
  }

  .additional-offer__title {
    font-size: 32px;
  }

  .additional-offer__description {
    max-width: 364px;
    margin-bottom: 55px;
  }

  .additional-offer__list {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 50px 20px;
    grid-template-areas: ". .";
  }
}

@media (max-width: 991.98px) {
  .additional-offer {
    margin-bottom: 110px;
  }

  .additional-offer__title {
    font-size: 28px;
  }

  .additional-offer__description {
    margin-bottom: 50px;

    font-size: 14px;
  }

  .additional-offer__img {
    width: 88px;
    height: 88px;
  }
}

@media (max-width: 767.98px) {
  .additional-offer {
    margin-bottom: 90px;
  }

  .additional-offer__title {
    font-size: 26px;
  }

  .additional-offer__description {
    max-width: calc(100% - 30px);
    width: 364px;
    margin-bottom: 45px;
  }

  .additional-offer__list {
    gap: 22px 14px;
  }
}

@media (max-width: 532.98px) {
  .additional-offer__list {
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
</style>
