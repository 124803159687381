<template>
  <section id="documents" class="documents">
    <div class="container">
      <div class="documents__title" v-if="content.documents_title" v-html="content.documents_title"></div>
      <div
        class="documents__description"
        v-if="content.documents_desc"
        data-aos="custom-appearance"
        data-aos-duration="800"
        data-aos-delay="200"
        v-html="content.documents_desc"></div>
      <ul class="documents__list" v-if="content.documents_list">
        <ItemDocuments v-for="item in content.documents_list.slice(0, countItem)" :key="item" :content="item" />
      </ul>
      <ButtonLink
        parent-class="documents__button"
        v-if="content.documents_list.length > countItem"
        v-on:click="outputItem"
        >Смотреть еще</ButtonLink
      >
    </div>
  </section>
</template>

<script>
import ButtonLink from "@/components/element/LinkApp.vue";
import ItemDocuments from "@/components/block/ItemDocumentsApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    ButtonLink,
    ItemDocuments,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.documents,
      countItem: 6,
    };
  },
  methods: {
    outputItem() {
      this.countItem *= 2;
    },
  },
  mounted() {
    Fancybox.bind('[data-fancybox="card-documents"]', {
      closeButton: "outside",
      dragToClose: false,
      Thumbs: false,
      Toolbar: false,
      Image: {
        zoom: false,
        click: false,
      },
    });
  },
};
</script>

<style>
.documents {
  margin-bottom: 170px;
}

.documents__title {
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  text-align: center;
  color: var(--title2-color-white);
}

.documents__description {
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 70px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--subtitle3-color-white);
}

.documents__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . . . . .";
  height: auto;
  margin-bottom: 60px;
}

.documents__button {
  margin: 0 auto;
}

@media (max-width: 1599.98px) {
  .documents {
    margin-bottom: 150px;
  }

  .documents__title {
    font-size: 40px;
    line-height: 120%;
  }

  .documents__description {
    max-width: 450px;
    margin-bottom: 50px;

    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .documents {
    margin-bottom: 130px;
  }

  .documents__title {
    font-size: 32px;
  }

  .documents__description {
    max-width: 364px;
    margin-bottom: 45px;
  }

  .documents__list {
    margin-bottom: 55px;
  }
}

@media (max-width: 991.98px) {
  .documents {
    margin-bottom: 110px;
  }

  .documents__title {
    font-size: 28px;
  }

  .documents__description {
    margin-bottom: 50px;

    font-size: 14px;
  }

  .documents__list {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px 20px;
    grid-template-areas: ". . .";
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .documents {
    margin-bottom: 90px;
  }

  .documents__title {
    font-size: 26px;
  }

  .documents__description {
    max-width: calc(100% - 30px);
    width: 364px;
    margin-bottom: 45px;
  }

  .documents__list {
    grid-template-columns: 1fr 1fr;
    gap: 30px 20px;
    grid-template-areas: ". .";
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 424.98px) {
  .documents__list {
    gap: 20px 15px;
  }
}
</style>
