<template>
  <li :class="classObject" data-aos="custom-appearance" data-aos-duration="1000" :data-aos-delay="order + '00'">
    <img class="card-advantages__img" v-lazy="$mediaWp(content.advantages_list_id_img, 'advantages')"
      :src="$mediaWp(content.advantages_list_id_img, 'advantages')" alt="" v-if="content.advantages_list_id_img" />
    <div class="card-advantages__name" v-if="content.advantages_list_title" v-html="content.advantages_list_title">
    </div>
    <p class="card-advantages__text" v-if="content.advantages_list_desc" v-html="content.advantages_list_desc"></p>
  </li>
</template>

<script>
export default {
  props: ["parentClass", "content", "order"],
  data() {
    return {
      classObject: {
        ["swiper-slide"]: this.swiper,
        ["card-advantages"]: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.card-advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  text-align: center;
}

.card-advantages__img {
  width: 90px;
  height: 90px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.card-advantages__name {
  margin-bottom: 20px;

  font-family: var(--title4-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title4-fonts-size);
  line-height: var(--title4-line-height);
  color: var(--title4-color-white);
}

.card-advantages__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

@media (max-width: 1599.98px) {
  .card-advantages__img {
    width: 70px;
    height: 70px;
    margin-bottom: 25px;
  }

  .card-advantages__name {
    margin-bottom: 15px;

    font-size: 16px;
  }

  .card-advantages__text {
    font-size: 14px;
    line-height: 160%;
  }
}

@media (max-width: 1199.98px) {
  .card-advantages__img {
    width: 62px;
    height: 62px;
    margin-bottom: 22px;
  }

  .card-advantages__name {
    margin-bottom: 11px;
  }
}
</style>
