<template>
  <section id="gallery" class="gallery">
    <div class="container">
      <div class="gallery__text">
        <div class="gallery__title" v-if="content.gallery_title" v-html="content.gallery_title"></div>
        <div class="gallery__description" v-if="content.gallery_desc" v-html="content.gallery_desc"></div>
      </div>
      <div
        class="gallery__swiper swiper"
        data-aos="custom-riding"
        data-aos-duration="1000"
        data-aos-easing="linear"
        v-if="content.gallery_list">
        <ul class="gallery__list swiper-wrapper">
          <ItemGallery swiper="true" v-for="item in content.gallery_list" :key="item" :content="item" />
        </ul>
        <div class="gallery__controls">
          <ButtonSlidePrev parent-class="gallery__prev" />
          <ButtonSlideNext parent-class="gallery__next" />
          <PaginationSlide parent-class="gallery__pagination" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ItemGallery from "@/components/block/ItemGalleryApp.vue";
import PaginationSlide from "@/components/element/PaginationSlide.vue";
import ButtonSlideNext from "@/components/element/ButtonSlideNextApp.vue";
import ButtonSlidePrev from "@/components/element/ButtonSlidePrevApp.vue";
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  components: {
    ItemGallery,
    PaginationSlide,
    ButtonSlideNext,
    ButtonSlidePrev,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.gallery,
    };
  },
  mounted() {
    new Swiper(".gallery__swiper", {
      slidesPerView: "auto",
      modules: [Navigation, Pagination],
      loop: true,
      speed: 500,
      navigation: {
        nextEl: ".gallery__next",
        prevEl: ".gallery__prev",
      },
      pagination: {
        el: ".gallery__pagination",
        type: "bullets",
      },
      breakpoints: {
        320: {
          spaceBetween: 15,
        },
        767: {
          spaceBetween: 20,
        },
        992: {
          spaceBetween: 30,
        },
      },
    });

    Fancybox.bind('[data-fancybox="card-gallery"]', {
      closeButton: "outside",
      dragToClose: false,
      Thumbs: false,
      Toolbar: false,
      Image: {
        zoom: false,
        click: false,
      },
    });
  },
};
</script>

<style>
.gallery {
  overflow: hidden;
  padding-bottom: 170px;
}

.gallery__text {
  margin-bottom: 70px;
}

.gallery__title {
  flex-grow: 0;
  margin: 0 auto;
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  text-align: center;
  color: var(--title2-color-white);
}

.gallery__description {
  max-width: 500px;
  flex-grow: 0;
  margin: 0 auto;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--subtitle3-color-white);
}

.gallery__swiper {
  overflow: visible;
}

.gallery__next,
.gallery__prev {
  position: absolute;
  top: calc(50% - 30px);
  z-index: 1;
}

.gallery__list {
  align-items: center;
}

.gallery__prev {
  left: calc(50% - 735px);
}

.gallery__next {
  right: calc(50% - 735px);
}

.gallery__pagination {
  display: none;
}

@media (max-width: 1599.98px) {
  .gallery {
    padding-bottom: 150px;
  }

  .gallery__text {
    margin-bottom: 66px;
  }

  .gallery__title {
    font-size: 40px;
  }

  .gallery__description {
    max-width: 450px;

    font-size: 14px;
  }

  .gallery__next,
  .gallery__prev {
    top: calc(50% - 22px);
  }

  .gallery__prev {
    left: calc(50% - 562px);
  }

  .gallery__next {
    right: calc(50% - 562px);
  }
}

@media (max-width: 1199.98px) {
  .gallery {
    padding-bottom: 130px;
  }

  .gallery__text {
    margin-bottom: 55px;
  }

  .gallery__title {
    font-size: 32px;
  }

  .gallery__description {
    max-width: 364px;
  }

  .gallery__next,
  .gallery__prev {
    top: calc(50% - 10px);
  }

  .gallery__prev {
    left: calc(50% - 503px);
  }

  .gallery__next {
    right: calc(50% - 503px);
  }
}

@media (max-width: 991.98px) {
  .gallery {
    padding-bottom: 110px;
  }

  .gallery__text {
    margin-bottom: 50px;
  }

  .gallery__title {
    font-size: 28px;
  }

  .gallery__description {
    width: 286px;

    font-size: 14px;
  }

  .gallery__next,
  .gallery__prev {
    display: none;
  }

  .gallery__pagination {
    display: flex;
    width: 290px !important;
    margin: 35px auto 0 auto;
  }
}

@media (max-width: 767.98px) {
  .gallery {
    padding-bottom: 90px;
  }

  .gallery__text {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 45px;
  }

  .gallery__title {
    font-size: 26px;
  }

  .gallery__description {
    max-width: calc(100% - 30px);
    width: 364px;
  }
}
</style>
